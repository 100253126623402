
const colors = {
    dark : '#161921',
    accent: '#BA6FEC',
    light: '#EAEFFC',
    grey: '#9DA6BF'

}


export {
    colors
}